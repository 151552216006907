import React from "react";
import styled from "styled-components";
import contactIcon from "../../assets/icon-contact.svg";
import Tick from "../../assets/icon-complete-copy-25.svg";

const Card = styled.div`
  width: 414px;
  height: 696px;
  border-radius: 16px;
  padding: 25px 30px 0 40px;
  box-shadow: 0 6px 20px 0 rgba(36, 45, 65, 0.1);
  background-color: #fff;
  margin-right: ${({ type }) => (type === "business" ? "0" : "20px")};
  position: relative;
  @media (max-width: 451px) {
    width: 100%;
    margin: 0 0 20px;
    padding: 16px;
  }
`;

const ColouredTop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 414px;
  max-width: 414px;
  height: 10px;
  border-radius: 16px 16px 0 0;
  background-color: ${({ type }) => {
    if (type === "free") return "rgba(36, 45, 65, 0.2)";
    if (type === "individual") return "#1bc689";
    if (type === "business") return "#000";
  }};
  @media (max-width: 1360px) {
    width: 100%;
  }
`;

const Text = styled.div`
  color: ${({ color }) => (color ? `${color}` : "#242d41")};
  font-size: ${({ size }) => (size ? `${size}` : "14px")};
  font-weight: ${({ weight }) => (weight ? `${weight}` : "400")};
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  line-height: ${({ lineHeight }) =>
    lineHeight ? `${lineHeight}` : "inherit"};
  text-transform: ${({ textTransform }) =>
    textTransform ? `${textTransform}` : "none"};
`;

const Wrapper = styled.div`
  display: flex;
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  flex-direction: ${({ flexDirection }) =>
    flexDirection ? `${flexDirection}` : "row"};
`;

const StyledImage = styled.img`
  width: 40px;
`;

const StyledButton = styled.button`
  height: 40px;
  border: ${({ type }) => (type === "free" ? "none" : "2px solid #1e5bb5")};
  color: ${({ type }) => (type === "free" ? "#fff" : "#1e5bb5")};
  background-color: ${({ type }) => (type === "free" ? "#1dd393" : "#fff")};
  font-size: 13px;
  border-radius: 20px;
  padding: 0 26px;
  font-weight: bold;
  margin-bottom: 32px;
  &:focus {
    outline: none;
  }
`;

const StyledTick = styled.img`
  width: 20px;
  margin-right: 10px;
`;

const LineWrapper = styled.div`
  margin-bottom: 16px;
  display: flex;
`;

const PlanCard = ({ type, onTrialClick, onDemoClick }) => {
  let title = "Free";
  let price = "0";
  let per = "/ 14 days";
  let belowPricing = "Limited time offer";
  let forType = "14 days";
  let list = [
    "Full access to MEL Manager for 14 days",
    "Pre-approved MEL templates",
    "PDF exports",
    "Basic customer support",
  ];
  if (type === "individual") {
    title = "Individual";
    price = "990";
    per = "/per aircraft";
    belowPricing = "$49/month thereafter";
    forType = "your aircraft";
    list = [
      "Full access to MEL Manager",
      "Pre-approved MEL templates for your aircraft type and regulator",
      "Create one customized aircraft MEL using smart workflows",
      "Automatic revision functionality",
      "Export as PDF or to Tech Log",
      "Basic customer support",
    ];
  }
  if (type === "business") {
    title = "Business";
    price = "";
    per = "";
    belowPricing = "Contact Sales for pricing";
    forType = "your fleet";
    list = [
      "All the features in the individual plan",
      "Create your own fleet-level templates based on the included MEL type and regulator templates",
      "Support for multiple aircraft and multiple operating entities",
      "Export as PDF or to Tech Log",
      "24/7 customer support",
    ];
  }

  const tickList = () =>
    list.map((item) => {
      return (
        <LineWrapper>
          <StyledTick src={Tick} alt="tick" />
          <Text size="16px" lineHeight="1.5" color="rgba(36, 45, 65, 0.9)">
            {item}
          </Text>
        </LineWrapper>
      );
    });

  return (
    <Card>
      <ColouredTop type={type} />
      <Text size="20px" weight="600" margin="10px 0 16px 0">
        {title}
      </Text>
      <Wrapper margin="0 0 10px 0">
        {type === "business" ? (
          <StyledImage src={contactIcon} alt="contact" />
        ) : (
          <>
            <Text size="20px" weight="600" margin="3px 0 0 0">
              $
            </Text>
            <Text size="48px" weight="600" margin="0 8px 0 0" lineHeight="40px">
              {price}
            </Text>
            <Text size="18px" margin="10px 0 0 0" color="rgba(36, 45, 65, 0.9)">
              {per}
            </Text>
          </>
        )}
      </Wrapper>
      <Text margin="10px 0 23px 0" color="rgba(36, 45, 65, 0.9)">
        {belowPricing}
      </Text>
      <Text
        size="20px"
        weight="600"
        lineHeight="1.2"
        margin="0 0 28px 0"
      >{`Discover all the capabilities of MEL Manager for ${forType}`}</Text>
      <StyledButton
        onClick={() => (type === "free" ? onTrialClick() : onDemoClick())}
        type={type}
      >
        {type === "free" ? "START YOUR FREE TRIAL" : "CONTACT US"}
      </StyledButton>
      <Text
        color="#1e5bb5"
        weight="bold"
        size="16px"
        textTransform="uppercase"
        margin="0 0 28px 0"
      >{`What you get on the ${type} plan:`}</Text>
      <Wrapper flexDirection="column">{tickList()}</Wrapper>
    </Card>
  );
};

export default PlanCard;
