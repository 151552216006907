import React, { Component } from "react";
import styled from "styled-components";
import { Container } from "react-bootstrap";
import melHero from "../assets/MEL/mel-video-hero.jpg";
import Seo from "../components/SEO";
import faaLogo from '../assets/MEL/logo-faa.svg';
import easaLogo from '../assets/MEL/logo-easa.svg';
import caaLogo from '../assets/MEL/logo-caa.svg';
import arubaLogo from '../assets/MEL/aruba.jpg';
import sanMarinoLogo from '../assets/MEL/san-marino.jpg';
import bermudaLogo from '../assets/MEL/bermuda.jpg';
import caymanLogo from '../assets/MEL/cayman.jpg';
import iomLogo from '../assets/MEL/logo-iom-caa.webp';
import dcaLogo from '../assets/MEL/dca.svg';
import airbus from '../assets/MEL/Airbus.svg';
import bombardier from '../assets/MEL/Bombardier.svg';
import dassault from '../assets/MEL/Dassault.svg';
import embraer from '../assets/MEL/Embraer.svg';
import beoing from '../assets/MEL/Boeing.svg';
import gulfstream from '../assets/MEL/Gulfstream.svg';
import transportCanada from '../assets/MEL/logo-transport-canada.svg';
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import PlanCard from "../components/MEL/PlanCard";
import MelOptionCard from "../components/MEL/MelOptionCard";
import NewTrialModal from "../components/MEL/newTrialModal";
import DemoModal from "../components/MEL/demoModal";

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
  margin-top: 90px;
  @media (max-width: 451px) {
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const CustomerQuoteSection = styled.div`
  background-image: linear-gradient(to left, #1e5bb5, #062955);
  padding: 100px 0px;
  font-size: 24px;
  line-height: 1.58;
  color: rgba(255, 255, 255, 0.9);
  @media (max-width: 451px) {
    padding: 30px 0%;
  }
`;

const Author = styled.p`
  font-weight: bold;
  @media (max-width: 451px) {
    text-align: center;
    font-size: 18px;
  }
`;

const Wrapper = styled.div`
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  padding: ${({ padding }) => (padding ? `${padding}` : "0")};
  @media (max-width: 451px) {
    margin: 0;
    padding: 20px;
  }
`;

const Header = styled.div`
  font-size: ${({ size }) => (size ? `${size}` : "24px")};
  font-weight: ${({ weight }) => (weight ? `${weight}` : "400")};
  line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}` : "1")};
  color: ${({ color }) => (color ? `${color}` : "#242d41")};
  text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : "left")};
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  @media (max-width: 451px) {
    margin: 20px 0;
  }
`;

const StyledLogo = styled.img`
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}` : "0")};
  width: ${({ width }) => (width ? `${width}` : "auto")};
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  @media (max-width: 451px) {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    justify-content: center;
    margin: 0 0 20px;
    ${StyledLogo} {
      margin: 0;
      height: 40px;
    }
  }
`;

const SupportWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ margin }) => (margin ? `${margin}` : "0")};
  @media (max-width: 451px) {
    gap: 20px;
    margin: 0 0 20px;
    ${StyledLogo} {
      margin: 0;
      width: 100px;
    }
  }
`;

const OptionsButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 60px;
  @media (max-width: 451px) {
    overflow-x: scroll;
  }
`;

const StyledButton = styled.button`
  width: 220px;
  height: 46px;
  font-size: 16px;
  border-radius: 23px;
  border: ${({ active }) => (active ? "2px solid rgba(36, 45, 65, 0.1)" : "solid 2px rgba(3, 212, 255, 0.1)")};
  font-weight: bold;
  color: ${({ active }) => (active ? '#1e5bb5' : 'rgba(36, 45, 65, 0.9)')};
  background: ${({ active }) => (active ? 'rgba(3, 212, 255, 0.1)' : '#fff')};
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}` : "0")};
  &:focus {
    outline: none;
  }
  @media (max-width: 451px) {
    flex-shrink: 0;
  }
`;

const OptionDisplay = styled.div``;

const PlanWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 82px;
  @media (max-width: 1290px) {
    flex-wrap: wrap;
    gap: 20px;
  }
`;

const Text = styled.div`
    color: ${({ color }) => (color ? `${color}` : '#242d41')};
    font-size: ${({ size }) => (size ? `${size}` : '14px')};
    font-weight: ${({ weight }) => (weight ? `${weight}` : '400')};
    margin: ${({ margin }) => (margin ? `${margin}` : '0')};
    line-height: ${({ lineHeight }) => (lineHeight ? `${lineHeight}` : 'inherit')};
    text-transform: ${({ textTransform }) => (textTransform ? `${textTransform}` : 'none')};
    text-align: ${({ textAlign }) => (textAlign ? `${textAlign}` : 'left')};
    @media (max-width: 451px) {
      margin: 20px 0;
    }
`;

const StyledPara = styled.p`
  @media (max-width: 451px) {
    text-align: center;
    font-size: 16px;
  }
`;

export default class melManager extends Component {
  constructor(props) {
    super(props);
    this.domRef = React.createRef();
    this.state = {
      activeTab: "smart",
      modalVisible: false,
      demoModalVisible: false,
    };
  }

  onTrialClick = () => {
    this.setState({ modalVisible: true });
  };

  onDemoClick = () => {
    this.setState({ demoModalVisible: true });
  };

  handleDemoClick = () => {
    this.setState({ modalVisible: false, demoModalVisible: true });
  };

  render() {
    const { activeTab, modalVisible, demoModalVisible } = this.state;
    return (
      <Layout popup={"mel"} noNavigation trial hideNewsletterFooter>
        <Seo
          title="MEL Manager"
          description="Effortlessly issue and revise Minimum Equipment Lists (MELs) for your fleet with MEL Manager's pre-approved templates."
        />
        <PageHeader
          header="Create MELs in 30 minutes: <br />start your 14-day trial today!"
          subHeader="Effortlessly issue and revise Minimum Equipment Lists (MELs) for your fleet with MEL Manager's pre-approved templates."
          bigHeader
          bigImage={melHero}
          scroll
          whiteLink
          svgImage
          trial
        />
        <Container>
          <Wrapper padding="60px 80px">
            <Header size="30px" weight="600" lineHeight="1.31" color="#242d41" textAlign="center" margin="0 0 60px 0">Supported by</Header>
            <LogoWrapper margin="0 0 48px 0">
              <StyledLogo src={faaLogo} alt="faa" marginRight="74px" />
              <StyledLogo src={transportCanada} alt="transport canada" marginRight="74px" />
              <StyledLogo src={easaLogo} alt="easa" marginRight="74px" />
              <StyledLogo src={caaLogo} alt="caa" marginRight="74px" />
              <StyledLogo src={iomLogo} alt="isle of man" marginRight="74px" />
              <StyledLogo src={dcaLogo} alt="dca" />
            </LogoWrapper>
            <LogoWrapper>
              <StyledLogo src={sanMarinoLogo} alt="san marino" marginRight="74px" />
              <StyledLogo src={bermudaLogo} alt="bermuda" marginRight="74px" />
              <StyledLogo src={caymanLogo} alt="caa" marginRight="74px" />
              <StyledLogo src={arubaLogo} alt="aruba" />
            </LogoWrapper>
          </Wrapper>
        </Container>
        <GradientSection>
          <Container>
            <Header size="30px" weight="600" lineHeight="1.31" color="#242d41" textAlign="center" margin="100px 0 40px 0">Create, revise and publish MELs as easily as possible</Header>
            <OptionsButtonWrapper>
              <StyledButton active={activeTab === "smart"} onClick={() => this.setState({ activeTab: "smart" })} marginRight="16px">Smart templates</StyledButton>
              <StyledButton active={activeTab === "tracking"} onClick={() => this.setState({ activeTab: "tracking" })} marginRight="16px">Change tracking</StyledButton>
              <StyledButton active={activeTab === "revisions"} onClick={() => this.setState({ activeTab: "revisions" })} marginRight="16px">Smart merge revisions</StyledButton>
              <StyledButton active={activeTab === "distribution"} onClick={() => this.setState({ activeTab: "distribution" })} marginRight="16px">MEL Management</StyledButton>
              <StyledButton active={activeTab === "integration"} onClick={() => this.setState({ activeTab: "integration" })} marginRight="16px">Tech Log integration</StyledButton>
              {/* <StyledButton active={activeTab === "fleet"} onClick={() => this.setState({ activeTab: "fleet" })}>Fleet view</StyledButton> */}
            </OptionsButtonWrapper>
            <OptionDisplay>
              <MelOptionCard type={activeTab} />
            </OptionDisplay>
            <PlanWrapper>
              <PlanCard type="free" onTrialClick={this.onTrialClick} />
              <PlanCard type="individual" onDemoClick={this.onDemoClick} />
              <PlanCard type="business" onDemoClick={this.onDemoClick} />
            </PlanWrapper>
          </Container>
        </GradientSection>
        <Container>
          <Text margin="35px 0 60px 0" size="30px" weight="600" lineHeight="1.31" color="#242d41" textAlign="center">Supported aircraft</Text>
            <SupportWrapper margin="0 0 73px 0">
              <StyledLogo width="185px" src={airbus} alt="airbus" marginRight="95px" />
              <StyledLogo width="185px" src={beoing} alt="boeing" marginRight="74px" />
              <StyledLogo width="227px" src={bombardier} alt="bombardier" />
            </SupportWrapper>
            <SupportWrapper margin="0 0 150px 0">
              <StyledLogo width="232px" src={gulfstream} alt="gulfstream" marginRight="62px" />
              <StyledLogo width="202px" src={embraer} alt="embraer" marginRight="85px" />
              <StyledLogo width="185px" src={dassault} alt="dassault" marginRight="46px" />
            </SupportWrapper>
        </Container>
        <CustomerQuoteSection>
          <Container>
            <StyledPara>
            “TrustFlight has been supporting our operations for the past months in both the development and implementation of a new MEL. The MEL Manager is exactly what we needed. It cut the time needed to develop a new MEL from several months to a few days. We would recommend using TrustFlight to anyone looking for a highly productive and solution driven team, and an efficient and useful MEL platform. We plan to continue to work with them for the long term.”
            </StyledPara>
            <Author>Rudolf Winter, Air JPL</Author>
          </Container>
        </CustomerQuoteSection>
        <form
          name="mel-manager-trial"
          method="post"
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="email" />
          <input type="hidden" name="name" />
          <input type="hidden" name="number" />
          <input type="hidden" name="identifier" />
          <input type="hidden" name="form-name" value="mel-manager-trial" />
        </form>
        {modalVisible ? (
          <NewTrialModal
            handleClose={() => {
              this.setState({ modalVisible: false });
              document.body.style.overflow = "auto";
            }}
            handleDemoClick={() => this.handleDemoClick()}
          />
        ) : null}
        {demoModalVisible ? (
          <DemoModal
            handleClose={() => {
              this.setState({ demoModalVisible: false });
              document.body.style.overflow = "auto";
            }}
          />
        ) : null}
      </Layout>
    );
  }
}
