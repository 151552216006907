/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import tick from '../../assets/icon-complete-copy-25.svg';
import masterMel from '../../assets/MEL/master-mel-table.jpg';
import trackingImage from '../../assets/MEL/tracking.png';
import revisionsImage from '../../assets/MEL/revisions.jpg';
import distributionImage from '../../assets/MEL/distribution.jpg';
import integrationImage from '../../assets/MEL/integration.jpg';

const Wrapper = styled.div`
    margin-bottom: 120px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    @media (max-width: 451px) {
        grid-template-columns: 1fr;
    }
`;

const StyledImg = styled.img`
    width: 100%;
`;

const ItemWrapper = styled.div`
    display: flex;
    align-items: flex-start;
`;

const StyledTick = styled.img`
    width: 24px;
    margin-right: 12px;
`;

const ListText = styled.div`
    font-size: 16px;
    line-height: 1.5;
    color: 'rgba(36, 45, 65, 0.9)';
    margin-bottom: 14px;
`;

const Header = styled.div`
    font-size: 24px;
    font-weight: 600;
    line-height: 1.33;
    color: '#242d41';
    margin-bottom: 16px;
`;

const SubHeading = styled.div`
    font-size: 18px;
    line-height: 1.56;
    color: 'rgba(36, 45, 65, 0.9)';
    margin-bottom: 28px;
`;

const MelOptionCard = ({ type }) => {
    const [displayData, setDisplayData] = useState(null);

    const displayOptions = [
        {
            type: 'smart',
            data: {
                header: 'Smart templates',
                subHeader: 'Issuing an Aircraft MEL is as simple as selecting a template in our system and configuring it to your aircraft.',
                img: masterMel,
                list: [
                    'Create Aircraft MELs with pre-configured templates',
                    'Full library of MEL templates from all major OEMs',
                    'Ability to customize AMELs based on specific requirements'
                ],
            }
        },
        {
            type: 'tracking',
            data: {
                header: 'Change tracking',
                subHeader: 'Track and compare content changes across different versions of MELs, ensuring complete visibility and control over the modification history.',
                img: trackingImage,
                list: [
                    'Effortlessly track and monitor changes made to MELs',
                    'Easily compare the current version of the MEL with the previous one',
                    'Gain complete visibility and control over the modification history of MELs'
                ],
            }
        },
        {
            type: 'revisions',
            data: {
                header: 'Smart merge revisions',
                subHeader: 'Say goodbye to manual updates and effortlessly generate MEL revisions that automatically incorporate changes from the source documents, ensuring your MEL is always up-to-date.',
                img: revisionsImage,
                list: [
                    'Automates the process of integrating changes made to the source documents into your Aircraft MEL.',
                    'Eliminate the need for manual updates and save valuable time by generating MEL revisions.',
                    'Ensure your Aircraft MEL remains current and aligned with the latest revisions of the source documents.'
                ],
            }
        },
        {
            type: 'distribution',
            data: {
                header: 'MEL Management',
                subHeader: 'From creation to review and distribution, efficiently manage your MELs in an organized manner, ensuring smooth collaboration with internal and external reviewers.',
                img: distributionImage,
                list: [
                    'Facilitates easy and effective MEL reviews by internal and external stakeholders',
                    'Ensuring data integrity of published MELs by preventing further edits or modifications',
                    'Easily export MELs to PDF format whenever needed, enabling hassle-free distribution'
                ],
            }
        },
        {
            type: 'integration',
            data: {
                header: 'Tech Log integration',
                subHeader: 'Publish MELs created in MEL Manager to eTechLog effortlessly with just a single click, enabling immediate accessibility in operational workflows.',
                img: integrationImage,
                list: [
                    'Fully integrated with TrustFlights eTechLog, allowing for a seamless connection between the two applications.',
                    'MELs created in MEL Manager can be published to eTechLog, ensuring immediate access for operational teams.',
                    'Reduce the time and effort to put revised MELs into operation'
                ],
            }
        }
    ];

    const displayList = (list) => {
        return list?.map((item) => {
            return (
                <ItemWrapper>
                    <StyledTick src={tick} alt="tick" />
                    <ListText>{item}</ListText>
                </ItemWrapper>
            )
        });
    };

    useEffect(() => {
        if (type) {
            const foundType = displayOptions.find((item) => item?.type === type);
            setDisplayData(foundType?.data);
        }
    }, [type]);

    return (
        <Wrapper>
            <div>
                <Header>{displayData?.header}</Header>
                <SubHeading>{displayData?.subHeader}</SubHeading>
                {displayList(displayData?.list)}
            </div>
            <StyledImg src={displayData?.img} alt="display" />
        </Wrapper>
    )
};

export default MelOptionCard;
